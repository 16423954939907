import axios from "axios";
import { ShowDialogError } from "./DialogError";

//export const BASE_URL = "https://thevineyard.app:5001/";
//export const BASE_URL = "https://thevineyard.app:5001/";
//export const BASE_URL = "https://thevineyard.app:5001/";

export const BASE_URL = "https://thevineyard.app:5001/";
export const API_URL = BASE_URL + "api";

export const logOut = () => {
  axios.defaults.withCredentials = true;
};

export const postApi = ({ url, params = {} }: any) => {
  return axios({
    method: "POST",
    url: `${API_URL}${url}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
    data: { ...params },
  });
};

export const FetchApi = async ({
  method,
  url,
  params = {},
  filterParams = null,
  inBackground = false,
}: any) => {
  const Data = params;
  const FilterParameters = filterParams;

  try {
    const response = await axios({
      method,
      url: `${API_URL}${url}`,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      data: filterParams
        ? FilterParameters
        : {
            Data,
          },
    });
    const data: any = response.data;
    if (data.isSuccess === false && !inBackground) {
      console.log("Error" + url, data.message);
      // ShowDialogError(data.message);
    }
    return {
      additionalData: data.additionalData,
      breadCrumb: data.breadCrumb,
      status: data.isSuccess ? "success" : "error",
      data: data.data,
      totalCount: data.totalCount,
      message: data.message,
    };
  } catch (error: any) {
    return {
      data: error,
      message: error.message,
      status: "error",
    };
  }
};

// export const toUpperCamelCase = (o:any) => {
//   let newO, origKey, newKey, value:any;
//   if (o instanceof Array) {
//     return o.map(function (value) {
//       if (typeof value === "object") {
//         value = toUpperCamelCase(value);
//       }
//       return value;
//     });
//   } else if (o instanceof Object) {
//     newO = {};
//     for (origKey in o) {
//       if (o.hasOwnProperty(origKey)) {
//         newKey = origKey;
//         if (origKey.toUpperCase() !== origKey) {
//           newKey = origKey;
//           newKey = (
//             origKey.charAt(0).toUpperCase() + origKey.slice(1) || origKey
//           ).toString();
//         }
//         value = o[origKey];
//         if (
//           value &&
//           (value instanceof Array ||
//             (value !== null && value.constructor === Object))
//         ) {
//           value = toUpperCamelCase(value);
//         }
//         newO[newKey] = value;
//       }
//     }
//   } else {
//     return o;
//   }
//   return newO;
// };

// export const toCamelCase = (o) => {
//   let newO, origKey, newKey, value;
//   if (o instanceof Array) {
//     return o.map(function (value) {
//       if (typeof value === "object") {
//         value = toCamelCase(value);
//       }
//       return value;
//     });
//   } else {
//     newO = {};
//     for (origKey in o) {
//       if (o.hasOwnProperty(origKey)) {
//         if (origKey.toUpperCase() === origKey) {
//           newKey = origKey;
//         } else {
//           newKey = (
//             origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
//           ).toString();
//         }
//         value = o[origKey];
//         if (
//           value instanceof Array ||
//           (value !== null && value.constructor === Object)
//         ) {
//           value = toCamelCase(value);
//         }
//         newO[newKey] = value;
//         if (origKey.toLowerCase() === "id") {
//           newO["ID"] = value;
//           newO["id"] = value;
//         }
//       }
//     }
//   }
//   return newO;
// };

export const handleFileSubmit = async (e: any, file: any, isSingle: any) => {
  if (e) {
    e.preventDefault();
  }
  var api = "SaveUploadedFile";
  if (isSingle === true) {
    api = "SaveSingleUploadedFile";
  }
  const url = API_URL + "/FileApi/" + api;
  const formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
    withCredentials: true,
  };
  //showProgress();

  const response = await axios.post(url, formData, config);
  //HideProgress();
  const data: any = response.data;
  if (data.isSuccess === false) {
    console.log("Error" + url, data.message);
    ShowDialogError(data.message);
  }
  return {
    status: data.isSuccess ? "success" : "error",
    data: data.data,
    totalCount: data.totalCount,
  };
};

export const uploadHandler = async (
  e: any,
  file: any,
  fileId: any,
  fileName: any,
  Type: any,
  handleChangeDocument: any
) => {
  const { data, status } = await handleFileSubmit(e, file, true);
  if (status === "success" && data) {
    handleChangeDocument(fileId, fileName, Type, data.fileName);
  }
  return data.fileName;
};
